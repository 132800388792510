import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import $ from 'jquery';
import InfiniteScroll from 'react-infinite-scroll-component';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from '../../Functions';
import { retreiveSettings } from '../../Functions/Settings';
import * as Profile from '../../Functions/Profile';

const FollowersModal = ({user, myInfos, list, session}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set req limit
    const [reqLimit, setReqLimit] = useState(0);
    const [reqLimitSearch, setReqLimitSearch] = useState(0);
    const [limit, setLimit] = useState(50);
    const [limitSearch, setLimitSearch] = useState(50);
    // Set data
    const [allFllws, setAllFllws] = useState(list);
    const [search, setSearch] = useState('');
    const [listLive, setLLive] = useState(<></>);
    const [access, setAccess] = useState(true);
    // Set loading (for search)
    const [loadingSearch, setLoadingSearch] = useState(true);

    /* ----------
     * BUILD LIST
     * ----------
     */
    const FollowersList = () => {
        useEffect(() => {
            if(allFllws !== 0){
                allFllws.map((listFollowers) => {
                    $('.ref_'+listFollowers.uid+" .profileBtn_uCPcVB1.fllw").off('click').on('click', () => navigate("/@"+listFollowers.usertag.toLowerCase().replace('.', '_')));
                })
            }
        }, [allFllws])

        if(user.access === "Public"){
            if(access || session === "current"){
                if(allFllws === 0){
                    return (
                        <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                            <i className="fi fi-rr-cross-circle" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                            <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.noFollowersSubs.title')}</span>
                            {t('profile.error.noFollowersSubs.description.followers.1')} @{user.usertag.toLowerCase().replace('.', '_')} {t('profile.error.noFollowersSubs.description.followers.2')}
                        </p>
                    )
                }else{
                    if(search === ''){
                        return (
                            <InfiniteScroll
                                dataLength={allFllws.length}
                                next={loadMore}
                                hasMore={allFllws.length !== limit ? false: true}
                                style={{ overflow: "hidden" }}
                                loader={<></>}
                                scrollableTarget="listScroll"
                                endMessage={<></>}
                            >
                                {allFllws.map((listFollowers) => {
                                    return (<>
                                        <div className={"userContainer ref_"+listFollowers.uid} key={listFollowers.uid}>
                                            <img src={listFollowers.avatar} alt={"@"+listFollowers.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+listFollowers.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+listFollowers.uid} onError={(e) => {
                                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                    e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                                }
                                            }} referrerPolicy='no-referrer' />
                                            <p id="usertag">@{listFollowers.usertag.toLowerCase().replace('.', '_')}</p>
                                            <div className="profileBtn_uCPcVB1 fllw">
                                                <span>{t('profile.modal.button')}</span>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </InfiniteScroll>
                        )
                    }else{
                        if(loadingSearch === true){
                            return (<>
                                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                                    <i className="fi fi-rr-search" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.search.title')}</span>
                                    {t('profile.search.description')}
                                </p>
                            </>)
                        }else{
                            return listLive;
                        }
                    }
                }
            }else{
                return (
                    <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                        <i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                        <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.private.title')}</span>
                        {t('profile.error.private.choice.followers')}
                    </p>
                )
            }
        }else{
            return (
                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                    <i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.private.title')}</span>
                    {t('profile.error.private.followers')}
                </p>
            )
        }
    }

    // Load more users
    const loadMore = () => {
        var newReqLimit = reqLimit + 50
        setReqLimit(newReqLimit);
        var newLimit = limit + 50
        setLimit(newLimit);
        // Fetch new users
        if(myInfos !== null){
            Profile.getFollowers(user.uid, myInfos.uid, newReqLimit, newLimit).then(res => {
                setAllFllws(allFllws.concat(res));
            })
        }else{
            Profile.getFollowers(user.uid, undefined, newReqLimit, newLimit).then(res => {
                setAllFllws(allFllws.concat(res));
            })
        }
    }

    /* FOR SEARCH FUNCTIONS */
    // Search for followers
    const searchFollowers = (query, initReq, initLimit) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${user.uid}/followers/search/?query=${query}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web',
                    req: initReq,
                    limit: initLimit
                }
            }).then((response) => {
                resolve(response.data.search);
            }).catch((error) => resolve(0))
        })
    }
    
    // Load more users (search)
    const loadMoreSearch = () => {
        var newReqLimit = reqLimitSearch + 50
        setReqLimitSearch(newReqLimit);
        var newLimit = limitSearch + 50
        setLimitSearch(newLimit);
        // Fetch new search users
        if(search !== ''){
            searchFollowers(search, newReqLimit, newLimit).then((results) => {
                if(results !== 0){
                    <InfiniteScroll
                        dataLength={results.length}
                        next={loadMoreSearch}
                        hasMore={results.length !== limit ? false: true}
                        style={{ overflow: "hidden" }}
                        loader={<></>}
                        scrollableTarget="listScroll"
                        endMessage={<></>}
                    >
                        {results.map((resultsFollowers) => {
                            setLLive(
                                <>
                                <div className={"userContainer ref_"+resultsFollowers.uid} key={resultsFollowers.uid}>
                                    <img src={resultsFollowers.avatar} alt={"@"+resultsFollowers.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+resultsFollowers.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+resultsFollowers.uid} onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                        }
                                    }} referrerPolicy='no-referrer' />
                                    <p id="usertag">@{resultsFollowers.usertag.toLowerCase().replace('.', '_')}</p>
                                    <div className="profileBtn_uCPcVB1 fllw">
                                        <span>{t('profile.modal.button')}</span>
                                    </div>
                                </div>
                                </>
                            );
                        })}
                    </InfiniteScroll>
                }
            })
        }
    }

    useEffect(() => {
        if(user.access !== "Private"){
            if(access || session === "current"){
                if(search !== ''){
                    searchFollowers(search, reqLimitSearch).then((results) => {
                        if(results !== 0){
                            results.map((resultsFollowers) => {
                                setLLive(
                                    <>
                                    <div className={"userContainer ref_"+resultsFollowers.uid} key={resultsFollowers.uid}>
                                        <img src={resultsFollowers.avatar} alt={"@"+resultsFollowers.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+resultsFollowers.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+resultsFollowers.uid} onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                            }
                                        }} referrerPolicy='no-referrer' />
                                        <p id="usertag">@{resultsFollowers.usertag.toLowerCase().replace('.', '_')}</p>
                                        <div className="profileBtn_uCPcVB1 fllw">
                                            <span>{t('profile.modal.button')}</span>
                                        </div>
                                    </div>
                                    </>
                                );
                            })
                        }else{
                            setLLive(
                                <>
                                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                                    <i className="fi fi-rr-interrogation" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.noFollowersSubs.title')}</span>
                                    {t('profile.error.noResults.description')}
                                </p>
                                </>
                            );
                        }
                        // Set loading
                        setLoadingSearch(false);
                    })
                }
            }

            // EVENT LISTENERS
            if(list !== 0){
                list.map((result) => {
                    $('.ref_'+result.uid+" .profileBtn_uCPcVB1.fllw").off('click').on('click', () => navigate("/@"+result.usertag.toLowerCase().replace('.', '_')));
                })
            }
        }
    }, [search, user, list, session])
    /* END OF SEARCH FUNCTIONS */

    useEffect(() => {
        if(user.access === "Public"){
            if(access || session === "current"){
                if(list !== 0){
                    list.map(result => {
                        $('.ref_'+result.uid+" .profileBtn_uCPcVB1.fllw").off('click').on('click', () => navigate("/@"+result.usertag.toLowerCase().replace('.', '_')));
                    })
                }
            }
        }
    }, [access, user, list, session])

    useEffect(() => {
        // Retreive settings of [user]
        retreiveSettings(user.uid, 'followers_setting').then(setting => {
            if(setting[0] !== null){
                if(setting[0]['privacy_setting2'] === "Private") setAccess(false);
            }
        })
    }, [user])

    return (
        <>
        <aside id="followersList" className="modal darkTheme" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='listScroll' className="modal-wrapper js-modal-stop" style={{backgroundColor: "#101010",padding: 0,height: 500,width: 500}}>
                <div className="topPart_fSLMPcB1 darkTheme">
                    <h1>Followers {t('profile.modal.subsFollows.title')} <span>@{user.usertag.toLowerCase().replace('.', '_')}</span></h1>
                    <button className="js-modal-close">
                        <i className="fi fi-rr-cross"></i>
                    </button>
                </div>
                <div className="mainPart_fSLMPcB1">
                    <div className="searchBox_mPFSLMPcVB1">
                        <div id="icon"><i className="fi fi-rr-search" style={{display: "flex",width: "fit-content",fontSize: 16,color: "#9A9A9A"}}></i></div>
                        <input id="input" type="search" name="searchFollowers" placeholder={t('profile.modal.search')+" @user"} onChange={(e) => setSearch(DOMPurify.sanitize(e.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}))}></input>
                    </div>
                    <div className="userList_mPFSLMPcVB1">
                        <FollowersList />
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

const SubsModal = ({user, myInfos, list, session}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set req limit
    const [reqLimit, setReqLimit] = useState(0);
    const [reqLimitSearch, setReqLimitSearch] = useState(0);
    const [limit, setLimit] = useState(50);
    const [limitSearch, setLimitSearch] = useState(50);
    // Set data
    const [allSubs, setAllSubs] = useState(list);
    const [search, setSearch] = useState('');
    const [listLive, setLLive] = useState(<></>);
    const [access, setAccess] = useState(true);
    // Set loading search
    const [loadingSearch, setLoadingSearch] = useState(false);

    /* ----------
     * BUILD LIST
     * ----------
     */
    const SubsList = () => {
        useEffect(() => {
            if(allSubs !== 0){
                allSubs.map((listSubs) => {
                    $('.ref_'+listSubs.uid+" .profileBtn_uCPcVB1.sub").off('click').on('click', () => navigate("/@"+listSubs.usertag.toLowerCase().replace('.', '_')));
                })
            }
        }, [allSubs])

        if(user.access === "Public"){
            if(access  || session === "current"){
                if(allSubs === 0){
                    return (
                        <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                            <i className="fi fi-rr-cross-circle" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                            <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.noFollowersSubs.title')}</span>
                            {t('profile.error.noFollowersSubs.description.subs.1')} @{user.usertag.toLowerCase().replace('.', '_')} {t('profile.error.noFollowersSubs.description.subs.2')}
                        </p>
                    )
                }else{
                    if(search === ''){
                        return (
                            <InfiniteScroll
                                dataLength={allSubs.length}
                                next={loadMore}
                                hasMore={allSubs.length !== limit ? false: true}
                                style={{ overflow: "hidden" }}
                                loader={<></>}
                                scrollableTarget="listScroll"
                                endMessage={<></>}
                            >
                                {allSubs.map((listSubs) => {
                                    return(<>
                                        <div className={"userContainer ref_"+listSubs.uid} key={listSubs.uid}>
                                            <img src={listSubs.avatar} alt={"@"+listSubs.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+listSubs.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+listSubs.uid} onError={(e) => {
                                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                    e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                                }
                                            }} referrerPolicy='no-referrer' />
                                            <p id="usertag">@{listSubs.usertag.toLowerCase().replace('.', '_')}</p>
                                            <div className="profileBtn_uCPcVB1 sub">
                                                <span>{t('profile.modal.button')}</span>
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </InfiniteScroll>
                        )
                    }else{
                        if(loadingSearch === true){
                            return(<>
                                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                                    <i className="fi fi-rr-search" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.search.title')}</span>
                                    {t('profile.search.description')}
                                </p>
                            </>);
                        }else{
                            return listLive;
                        }
                    }
                }
            }else{
                return (
                    <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                        <i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                        <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.private.title')}</span>
                        {t('profile.error.private.choice.subs')}
                    </p>
                )
            }
        }else{
            return (
                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                    <i className="fi fi-rr-lock" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.private.title')}</span>
                    {t('profile.error.private.subs')}
                </p>
            )
        }
    }

    // Load more users
    const loadMore = () => {
        var newReqLimit = reqLimit + 50
        setReqLimit(newReqLimit);
        var newLimit = limit + 50
        setLimit(newLimit);
        // Fetch new users
        if(myInfos !== null){
            Profile.getSubscriptions(user.uid, myInfos.uid, newReqLimit, newLimit).then(res => {
                setAllSubs(allSubs.concat(res));
            })
        }else{
            Profile.getSubscriptions(user.uid, undefined, newReqLimit, newLimit).then(res => {
                setAllSubs(allSubs.concat(res));
            })
        }
    }

    /* FOR SEARCH FUNCTIONS */
    // Search for subscriptions
    const searchSubs = (query, initReq, initLimit) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${user.uid}/subs/search/?query=${query}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web',
                    req: initReq,
                    limit: initLimit
                }
            }).then((response) => {
                resolve(response.data.search);
            }).catch((error) => resolve(0))
        })
    }

    // Load more users (search)
    const loadMoreSearch = () => {
        var newReqLimit = reqLimitSearch + 50
        setReqLimitSearch(newReqLimit);
        var newLimit = limitSearch + 50
        setLimitSearch(newLimit);
        // Fetch new search users
        if(search !== ''){
            searchSubs(search, newReqLimit, newLimit).then((results) => {
                if(results !== 0){
                    <InfiniteScroll
                        dataLength={results.length}
                        next={loadMoreSearch}
                        hasMore={results.length !== newLimit ? false: true}
                        style={{ overflow: "hidden" }}
                        loader={<></>}
                        scrollableTarget="listScroll"
                        endMessage={<></>}
                    >
                        {results.map((resultsSubs) => {
                            setLLive(
                                <>
                                <div className={"userContainer ref_"+resultsSubs.uid} key={resultsSubs.uid}>
                                    <img src={resultsSubs.avatar} alt={"@"+resultsSubs.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+resultsSubs.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+resultsSubs.uid} onError={(e) => {
                                        if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                            e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                        }
                                    }} referrerPolicy='no-referrer' />
                                    <p id="usertag">@{resultsSubs.usertag.toLowerCase().replace('.', '_')}</p>
                                    <div className="profileBtn_uCPcVB1 sub">
                                        <span>{t('profile.modal.button')}</span>
                                    </div>
                                </div>
                                </>
                            );
                        })}
                    </InfiniteScroll>
                }
            })
        }
    }

    useEffect(() => {
        if(user.access !== "Private"){
            if(access || session === "current"){
                if(search !== ''){
                    searchSubs(search, reqLimitSearch).then((results) => {
                        if(results !== 0){
                            results.map((resultsSubs) => {
                                setLLive(
                                    <>
                                    <div className={"userContainer ref_"+resultsSubs.uid} key={resultsSubs.uid}>
                                        <img src={resultsSubs.avatar} alt={"@"+resultsSubs.usertag.toLowerCase().replace('.', '_')+"'s avatar"} title={"@"+resultsSubs.usertag.toLowerCase().replace('.', '_')} className={"avatar_uCPcVB1 ref_"+resultsSubs.uid} onError={(e) => {
                                            if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                e.currentTarget.src = "https://api.snot.fr/v"+API_VERSION+"/content/icon_profile?ext=webp";
                                            }
                                        }} referrerPolicy='no-referrer' />
                                        <p id="usertag">@{resultsSubs.usertag.toLowerCase().replace('.', '_')}</p>
                                        <div className="profileBtn_uCPcVB1 sub">
                                            <span>{t('profile.modal.button')}</span>
                                        </div>
                                    </div>
                                    </>
                                );
                            })
                        }else{
                            setLLive(
                                <>
                                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto"}}>
                                    <i className="fi fi-rr-interrogation" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center"}}></i>
                                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 20,marginBottom: 10,justifyContent: "center"}}>{t('profile.error.noFollowersSubs.title')}</span>
                                    {t('profile.error.noResults.description')}
                                </p>
                                </>
                            );
                        }
                        // Set loading
                        setLoadingSearch(false);
                    })
                }
            }

            // EVENT LISTENERS
            if(list !== 0){
                list.map((result) => {
                    $('.ref_'+result.uid+" .profileBtn_uCPcVB1.sub").off('click').on('click', () => navigate("/@"+result.usertag.toLowerCase().replace('.', '_')));
                })
            }
        }
    }, [search, user, list, session])
    /* END OF SEARCH FUNCTIONS */

    useEffect(() => {
        if(user.access === "Public"){
            if(access || session === "current"){
                if(list !== 0){
                    list.map(result => {
                        $('.ref_'+result.uid+" .profileBtn_uCPcVB1.sub").off('click').on('click', () => navigate("/@"+result.usertag.toLowerCase().replace('.', '_')));
                    })
                }
            }
        }
    }, [access, user, list, session])

    useEffect(() => {
        // Retreive settings
        retreiveSettings(user.uid, 'subs_setting').then(setting => {
            if(setting[0] !== null){
                if(setting[0]['privacy_setting3'] === "Private") setAccess(false);
            }
        })
    }, [user])

    return (
        <>
        <aside id="subsList" className="modal darkTheme" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='listScroll' className="modal-wrapper js-modal-stop" style={{backgroundColor: "#101010",padding: 0,height: 500,width: 500}}>
                <div className="topPart_fSLMPcB1 darkTheme">
                    <h1>{t('profile.subs')} {t('profile.modal.subsFollows.title')} <span>@{user.usertag.toLowerCase().replace('.', '_')}</span></h1>
                    <button className="js-modal-close">
                        <i className="fi fi-rr-cross"></i>
                    </button>
                </div>
                <div className="mainPart_fSLMPcB1">
                    <div className="searchBox_mPFSLMPcVB1">
                        <div id="icon"><i className="fi fi-rr-search" style={{display: "flex",width: "fit-content",fontSize: 16,color: "#9A9A9A"}}></i></div>
                        <input id="input" type="search" name="searchSubs" placeholder={t('profile.modal.search')+" @user"} onChange={(e) => setSearch(DOMPurify.sanitize(e.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}))}></input>
                    </div>
                    <div className="userList_mPFSLMPcVB1">
                        <SubsList />
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

export {
    FollowersModal,
    SubsModal
};