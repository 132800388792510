// import Skeleton
import { useEffect, useState } from "react"
import Skeleton from "./Animation/Skeleton"

export default function PostLoading() {
    const [theme, setTheme] = useState('lightTheme');

    useEffect(() => {
        setTheme(localStorage.getItem('theme')+'Theme');
    }, [])

    return (
        <>
            <div className={"spost_mAppMVeB1 "+theme}>
                <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: -1, marginTop: 0,}}>
                    <Skeleton className='avatar' theme={theme} />
                    <Skeleton className='user' theme={theme} />
                </div>
                <div className="spostContent_spostMAppMVB1" style={{marginLeft: 45, marginTop: 30}}>
                    <Skeleton className="text" theme={theme} />
                </div>
                <div className="countInteractions_spostMAppMVB1">
                    <div style={{ marginRight: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-heart" />
                    </div>
                    <div id="separator"></div>
                    <div style={{ marginRight: 10, marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-comment-alt" />
                    </div>
                    <div id="separator"></div>
                    <div style={{ marginLeft: 10, marginRight:0, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-share" />
                    </div>
                </div>
            </div>
        </>
    )
}

export function PostLoadingWithMedia(){
    const [theme, setTheme] = useState('lightTheme');

    useEffect(() => {
        setTheme(localStorage.getItem('theme')+'Theme');
    }, [])

    return (
        <>
            <div className={"spost_mAppMVeB1 "+theme}>
                <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: -1, marginTop: 0,}}>
                    <Skeleton className='avatar' theme={theme} />
                    <Skeleton className='user' theme={theme} />
                </div>
                <div className="spostContent_spostMAppMVB1" style={{marginLeft: 45, marginTop: 30}}>
                    <Skeleton className="text" theme={theme} />
                    <Skeleton className="contentMedia" theme={theme} />
                </div>
                <div className="countInteractions_spostMAppMVB1">
                    <div style={{ marginRight: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-heart" />
                    </div>
                    <div id="separator"></div>
                    <div style={{ marginRight: 10, marginLeft: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-comment-alt" />
                    </div>
                    <div id="separator"></div>
                    <div style={{ marginLeft: 10, marginRight:0, display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <span>-</span>
                        <i className="fi fi-sr-share" />
                    </div>
                </div>
            </div>
        </>
      )
}

export function SpostLoading(){
    return (
        <>
            <div className="topPart_mAppMVB1" >
                <div className="skeleton text darkTheme" style={{marginBottom: 0, marginTop: 20, height: 40}}></div>
            </div>
            <div className="spost_sMAMVeB1 darkTheme" style={{ marginTop: 10 }}>
                <div id="spostMain">
                    <div className="topPart_sMainMVB1 darkTheme">
                        <div className="profilePart_spostMAppMVB1" style={{flex: 1, flexDirection: "row", alignItems: "flex-start", zIndex: 1, marginTop: 0,}}>
                            <div className='skeleton avatar darkTheme' style={{ width: 50, height: 50 }} />
                            <div className='skeleton user darkTheme' style={{ marginLeft: 10, marginTop: 2.5 }} />
                            <div className="datePart_sMainMVB1"></div>
                        </div>
                    </div>
                    <div className="spostContent_sMainMVB1 darkTheme">
                        <div className='skeleton text darkTheme' style={{ marginTop: 0, height: 30 }} />
                    </div>
                    <div className="mediaSpost skeleton darkTheme" style={{ width: 500, height: 300 }}></div>
                </div>
            </div>
            <div className="countInteractions_sMAMVeB1 darkTheme">
                <div id="like" style={{ marginRight: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span>-</span>
                    <i className="fi fi-sr-heart" />
                </div>
                <div id="separator"></div>
                <div id="comment" style={{ marginRight: 15, display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span>-</span>
                    <i className="fi fi-sr-comment-alt" />
                </div>
                <div id="separator"></div>
                <div id="share" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <span>-</span>
                    <i className="fi fi-sr-share" />
                </div>
            </div>
        </>
    )
}