import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Cookies from "js-cookie";
import axios from "axios";
import $ from 'jquery';
import DOMPurify from 'dompurify';
import * as marked from 'marked';
import { mangle } from "marked-mangle";
// import i18next
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import { Appbar, Navbar } from '../../Components/Common';
import { LoadingHome } from "../../Components/Loading/Pages/LoadingHome";
import EmojiPicker from "../../Components/Modals/Common/EmojiPicker";
// import Functions
import { API_VERSION } from "../../Components/Functions";
import { openNewMenu } from "../../Components/Functions/MenuNew";
 // import Auth
import { retreiveInfos } from "../../Components/Functions/Auth";
// import Emoji
    // -- Nature emojis
    import { emojis_nature } from "../../Components/Functions/Emojis/Nature";

const Add = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(document.location.search);
    // Generate SPOST UID
    const generatedUID = () => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/posts/generate/uid`
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }
    // Set data
    const [data, setData] = useState({
        uid: '',
        title: '',
        content: '',
        add: ''
    });
    const [tags, setTags] = useState([]);
    const [medias, setMedias] = useState([]);
    const [promoted, setPromoted] = useState('No');
    const [promotedStatus, setPromotedStatus] = useState(false);
    // -- For string length
    const [allLength, setLength] = useState({
        titleLength: 0,
        contentLength: '',
        addLength: 0
    })
    // Set general data
    const [logged, setLogged] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [nsfw, setNSFW] = useState('');
    // Set infos for [user]
    const [infos, setInfos] = useState({ uid: '' });
    const [logs, setLogs] = useState([]);
    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set logged
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
        }else{
            window.location.replace('/login');
        }
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }
    }, [localStorage.getItem('theme')])
    // Set file format (accepted)
    const acceptedFF = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        // VIDEO
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
        // AUDIO
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
        // OTHER (APPLICATION)
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.ms-powerpoint',
        'application/vnd.oasis.opendocument.text',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'text/plain'
    ]
    // -- Only images, videos and audio
    const acceptedImage = [
        // IMAGE
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
    ]
    const acceptedVideo = [
        "video/mj2",
        "video/mp4",
        "video/mp2t",
        "video/x-msvideo",
        "video/mpeg",
        "video/ogg",
        "video/webm",
        "video/3gpp",
        "video/3gpp2",
        "video/3gpp-tt",
        "video/AV1",
        "video/H264",
        "video/matroska",
        "video/raw",
    ]
    const acceptedAudio = [
        'audio/3gpp',
        'audio/3gpp2',
        'audio/aac',
        'audio/ac3',
        'audio/matroska',
        'audio/mhas',
        'audio/midi-clip',
        'audio/MPA',
        'audio/mp4',
        'audio/MP4A-LATM',
        'audio/mpa-robust',
        'audio/mpeg',
        'audio/mpeg4-generic',
        'audio/ogg',
        'audio/webm',
        "audio/wav",
    ]

    // FOR STREAK BAR
    const oneDay = 24 * 60 * 60 * 1000;

    // -- Compare today and last day streak was done
    const today = new Date();
          today.setHours(0, 0, 0, 0);

    // -- TO KNOW IF [USER] VALIDATE TODAY'S STREAK
    const match_date = new Date(logs.day);
          match_date.setHours(0, 0, 0, 0);

    const diff = Math.floor((today - match_date) / oneDay);
    let diffDays = parseInt(diff);

    var lastStreak = '';
    switch (diffDays) {
        case 0:
            lastStreak = "done";
            break;
        case 1:
            lastStreak = "not_done";
            break;
        default:
            lastStreak = "not_done_finished";
    }

    /* --------
     * FOR TAGS
     * --------
     */
    // -- To select tags
    const selectTag = (tag) => {
        var tagContainer = $("#"+tag);

        if (tagContainer.hasClass('selected')) {
            tagContainer.removeClass("selected");
            // Remove [tag] from array
            const index = tags.indexOf(tag);
            if (index > -1) {
                tags.splice(index, 1);
            }
        }else {
            tagContainer.addClass("selected");
            tags.push(tag);
        }
    }

    // -- To show add box for tags
    const showAddTag = () => {
        var addBox = $('.searchTagSpost_aSPMVB1');
        var addContainer = $(".tagsSpost_aSPMVB1 #add");

        if(addBox.css('display') === "none"){
            addBox.css('display', 'block');
            addContainer.addClass("selected");
        }else{
            addBox.css('display', 'none');
            addContainer.removeClass("selected");
        }
    }
    // -- Add tags with add box (when pressing enter)
    const addTag = (value) => {
        const sectionTags = $('.tagsSpost_aSPMVB1');
        const addButton = $(".tagsSpost_aSPMVB1 #add");

        // -- -- Create the tag element
        var newTag = document.createElement('div');
            newTag.id = value;
            newTag.className = "selected";
            newTag.style.width = "auto";
            newTag.style.padding = "0 10px 0 10px";
            newTag.textContent = value;
            newTag.addEventListener('click', () => {
                // Remove [tag] from array
                const indexNT = tags.indexOf(value);
                if (indexNT > -1) {
                    tags.splice(indexNT, 1);
                }
                // Remove the tag
                $('#'+value).remove();
            })
        // -- -- Append tag
        sectionTags.append(newTag, addButton);
        
        // -- -- Clear add box
        $('.searchTagSpost_aSPMVB1 #tagAdd').val('');
        setLength({ ...allLength, addLength: 0 })
    }

    /* ---------
     * FOR FILES
     * ---------
     */
    const setFiles = (files) => {
        for(const file of files){
            if(acceptedFF.includes(file.type)){
                $('.chooseFile_mSMVB1 #mediaText').text(file.name);
                // Replace extension
                var newExt = file.type;
                var newExtAPI = file.name.substr(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
                var replacedExtension = data.uid+'.'+newExtAPI;
                if (acceptedImage.includes(file.type)) {
                    replacedExtension = data.uid+".webp";
                    newExt = "image/webp";
                    newExtAPI = "webp";
                }else if (acceptedVideo.includes(file.type)) {
                    replacedExtension = data.uid+".webm";
                    newExt = "video/webm";
                    newExtAPI = "webm";
                }else if (acceptedAudio.includes(file.type)) {
                    replacedExtension = data.uid+".aac";
                    newExt = "audio/aac";
                    newExtAPI = "aac";
                }
                // Push media
                if(medias.length >= 1){
                    medias.splice(0, 1);
                }
                medias.push('https://api.snot.fr/v'+API_VERSION+'/content/spost/'+infos.uid+'/'+data.uid+"?ext="+newExtAPI);
                // Upload file when submit button is clicked
                $('#submit').on('click', () => uploadFile(replacedExtension, URL.createObjectURL(file), newExt));
            }else{
                setError('Sorry, you can\'t select this file.');
                if(i18n.resolvedLanguage === "fr"){
                    setError('Tu ne peux pas séléctionner ce fichier, désolé!')
                }
            }
        }
    }

    // -- Upload file
    const uploadFile = (fileName, dataObj, fileExt) => {
        var fileObject = fetch(dataObj)
        .then(r => r.blob())
        .then(blob => {
            fileObject = new File([blob], fileName, {type: fileExt});
            // ...
            const formData = new FormData();
            formData.append("file", fileObject, fileName);
            formData.append("user", infos.uid);
            formData.append("uid", data.uid);
            // UPLOAD
            axios.post(`https://api.snot.fr/v${API_VERSION}/upload/media-spost`, formData, {
                params: {
                    token: Cookies.get('loginToken'),
                    uid: infos.uid,
                    app: 'web'
                }
            }).then(() => {
                // -- VERIFY MEDIA (NSFW)
                const nsfwTerms = ['nsfw', 'nsfw (+18)', 'nsfw +18', 'porn', 'gore']
                // -- -- Verify if file is an image & user didn't already checked NSFW
                if(acceptedImage.includes(fileExt) && !tags.some(item => nsfwTerms.includes(item))){
                    axios.post(
                        `https://api.snot.fr/v${API_VERSION}/ia/detect/nsfw?token=${Cookies.get('loginToken')}&uid=${infos.uid}&app=web`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            data: { url: '../content.snot.fr/media/spost/'+infos.uid+'/'+fileName, type: fileExt }
                        }
                    ).then(response => {
                        if(response.data.nsfw === 1){
                            tags.push('nsfw');
                        }
                    })
                }
            })
        });
    }

    /* ----------------
     * PUBLISH THE POST
     * ----------------
     */
    const publish = () => {
        $('.errorInfoLI_tLIB1').css('color', '#db4e4e');
        if(data.title === "" && data.content === "") {
            setError("You must enter a title and content.");
            if(i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer le titre et le contenu.");
            }
            // Set error UX
            $('.titleSpost_aSPMVB1 #title').css({ borderColor: "#db4e4e" });
            $('.textSpost_aSPMVB1 #content').css({ borderColor: "#db4e4e" });
        }
        else if (data.title === "") {
            setError("You must enter a title.");
            if(i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer un titre.");
            }
            // Set error UX
            $('.titleSpost_aSPMVB1 #title').css({ borderColor: "#db4e4e" });
        }
        else if(data.content === "") {
            setError("You must enter content.");
            if(i18n.resolvedLanguage === "fr"){
                setError("Vous devez entrer le contenu.");
            }
            $('.textSpost_aSPMVB1 #content').css({ borderColor: "#db4e4e" });
        }else {
            $('#submit').off('click');
            if(theme === "darkTheme"){
                $('#submit').css({
                    backgroundColor: '#9A9A9A',
                    cursor: 'not-allowed'
                });
            }else{
                $('#submit').css({
                    backgroundColor: '#CFCFCF',
                    cursor: 'not-allowed'
                });
            }
            // Set info text
            setError('We\'re publishing your Spost, please wait...')
            if(i18n.resolvedLanguage === "fr"){
                setError('Nous publions votre Spost, veuillez patienter...');
            }
            $('.errorInfoLI_tLIB1').css('color', '#3c9fd8');
            /* ----------
             * FOR MARKED
             * ----------
             */
            // -- Override function
            const renderer = {
                link(href) {
                    return `<a href="${href}" target="_blank" rel="noreferrer" title="${href.replaceAll('mailto:', '')}">${href.replaceAll('mailto:', '')}</a>`;
                }
            };
            // -- Set options
            marked.use({
                breaks: true,
                renderer
            }, mangle());
            
            // ...
            if(!logs.message) {
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${infos.uid}/streak/logs/create?token=${Cookies.get('loginToken')}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    }
                );
                // If streak is not active anymore
                if(lastStreak === "not_done_finished"){
                    axios.put(
                        `https://api.snot.fr/v${API_VERSION}/${infos.uid}/streak/logs/update?token=${Cookies.get('loginToken')}&app=web`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            }
                        }
                    );
                }
                // Publish the post
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/posts?token=${Cookies.get('loginToken')}&uid=${infos.uid}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { uid: data.uid, user: infos.uid, title: data.title, content: marked.parseInline(data.content), content_clear: data.content, media: JSON.stringify(medias), tags: JSON.stringify(tags), promoted: promoted },
                    }
                ).then((response) => {
                    // Set result
                    setError(`Your post has been published!`);
                    if(i18n.resolvedLanguage === "fr"){
                        setError(`Votre post a été publié !`);
                    }
                    navigate('/spost/'+data.uid);
                })
            }else{
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/${infos.uid}/streak/logs/create?token=${Cookies.get('loginToken')}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    }
                );
                // Publish the post
                axios.post(
                    `https://api.snot.fr/v${API_VERSION}/posts?token=${Cookies.get('loginToken')}&uid=${infos.uid}&app=web`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: { uid: data.uid, user: infos.uid, title: data.title, content: marked.parseInline(data.content), content_clear: data.content, media: JSON.stringify(medias), tags: JSON.stringify(tags), promoted: promoted },
                    }
                ).then((response) => {
                    // Set result
                    setError(`Your post has been published!`);
                    if(i18n.resolvedLanguage === "fr"){
                        setError(`Votre post a été publié !`);
                    }
                    navigate('/spost/'+data.uid);
                })
            }

            // Delete cookie
            Cookies.remove('streak-seen');
            // Delete localStorage
            localStorage.removeItem('media-spost-filetype');
            localStorage.removeItem('media-spost-filename');
        }
    }

    // Check streak logs for [user] logged in
    const checkStreakLogs = (user) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/${user}/streak/logs`,
                headers: {
                    "Content-Type": "application/json",
                }
            }).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                reject(error);
            })
        })
    }

    // FETCH CLAIMED CASES
    const [claimed, setClaimed] = useState([]);
    const claimedCases = async (uid) => {
        try {
            const claimed = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/christmas/${uid}/gift/claimed`,
                {
                    headers: { "Content-Type": 'application/json' },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(res => res.data.claimed)

            if(claimed === null){
                setClaimed([]);
            }else{
                const result = claimed.map(item => item.case_day);
                setClaimed(result);
            }
        }catch(err) {
            console.log('Error while fetching claimed cases : ', err)
        }
    }

    // FETCH POST PROMOTED COUNT
    const promotedPost = async (user) => {
        try {
            const promoted = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/posts/${user}/promoted`,
                {
                    headers: { "Content-Type": 'application/json' },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(res => res.data)

            setPromotedStatus(promoted);
        }catch(err) {
            console.log('Error while fetching claimed cases : ', err)
        }
    }

    useEffect(() => {
        // Set uid for data
        generatedUID().then((res) => {
            setData({ ...data, uid: res.uid })
        })

        if(logged === 1){
            // Retreive user infos
            retreiveInfos().then((res) => {
                // Set infos
                setInfos({ uid: res.uid });
                // Check streak logs
                checkStreakLogs(res.uid).then((logs) => {
                    if(logs.success === true){
                        setLogs(logs.logs);
                    }else{
                        setLogs(logs)
                    }
                    // Set loading
                    setLoading(false);
                })
                // Fetch claimed cases
                claimedCases(res.uid);
                // Fetch promoted count
                promotedPost(res.uid);
            })
        }
    }, [logged])

    useEffect(() => {
        // Set errors when max length is exceeded
        if(allLength.titleLength > 50){
            $('.titleSpost_aSPMVB1 #title').css({ borderColor: "#db4e4e" });
        }
        if(allLength.contentLength > '2500/2500'){
            $('.textSpost_aSPMVB1 #content').css({ borderColor: "#db4e4e" });
        }
        if(allLength.addLength > 20){
            $('.searchTagSpost_aSPMVB1 #tagAdd').css({ borderColor: "#db4e4e" });
        }else{
            $('.searchTagSpost_aSPMVB1 #tagAdd').css({ borderColor: "#26262C" })
        }
    }, [allLength])

    // MUTATION OBSERVER
    useEffect(() => {
        if(!loading){
            const editableDiv = document.getElementById('content');
            const observer = new MutationObserver((mutationsList, observer) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        mutation.addedNodes.forEach(node => {
                            if (node.tagName === 'IMG') {
                                var content = editableDiv.innerHTML;
                                    content += node.outerHTML;
                                // Set data
                                setData({ ...data, content: DOMPurify.sanitize(content, {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br', 'img'], FORBID_ATTR: ['style']})});
                                if(editableDiv.textContent.length >= 2000) {
                                    setLength({ ...allLength, contentLength: editableDiv.textContent.length+'/2500' });
                                }else {
                                    setLength({ ...allLength, contentLength: '' });
                                }
                                // Auto scroll to bottom
                                editableDiv.scrollTop = editableDiv.scrollHeight;
                                // Error UX
                                if(editableDiv.innerHTML.trim() !== "") {
                                    $('.textSpost_aSPMVB1 #content').css({ borderColor: "#26262C" })
                                }else{
                                    $('.textSpost_aSPMVB1 #content').css({ borderColor: "#db4e4e" })
                                }
                            }
                        });
                    }
                }
            });

            observer.observe(editableDiv, {
                childList: true,
                subtree: true,
            });

            return () => {
                observer.disconnect();
            };
        }
    }, [loading]);

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/add" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.add')}/>
                <meta property="og:url" content={"https://snot.fr/add"+window.location.search}/>
                <meta name="twitter:title" content={t('title.add')}/>
                <title>{t('title.add')}</title>
            </Helmet>
            {
                loading === true ?
                    <LoadingHome />
                :
                <>
                    <Appbar />
                    <Navbar />
                    <div id="addSpostScroll" style={{ position: 'absolute', width: "100%", height: "100%", overflowY: "auto" }}>
                        <main id="addSpostPart" style={{ position: "absolute", left: 0, right:0 }}>
                            <div className={"addSpostPart_aSPMVB1 "+theme}>
                                <div className="titlePage_aSPMVB1">
                                    <h1 id="title">{t('add.title')}</h1>
                                    <h2 id="subtitle">{t('add.subtitle')}</h2>
                                    <p className="errorInfoLI_tLIB1" style={{ margin: "-5px 0px 15px 0px", fontSize: 14, fontWeight: '500' }}>{error}</p>
                                </div>
                                <form method="post" id="formSpost" encType="multipart/form-data">
                                    <div className="titleSpost_aSPMVB1">
                                        <input title="Titre du spost" name="title" id="title" type="text" maxLength="50" tabIndex={1} placeholder={t('add.form.title')} required
                                            onChange={(title) => {
                                                setData({ ...data, title: DOMPurify.sanitize(title.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}})});
                                                setLength({ ...allLength, titleLength: title.target.value.length });
                                                // Error UX
                                                if(title.target.value.trim() !== "") {
                                                    $('.titleSpost_aSPMVB1 #title').css({ borderColor: "#26262C" })
                                                }else{
                                                    $('.titleSpost_aSPMVB1 #title').css({ borderColor: "#db4e4e" })
                                                }
                                            }}
                                        />
                                        <span id="charactersCount_title">{allLength.titleLength}/50</span>
                                    </div>
                                    <div className="textSpost_aSPMVB1">
                                        <div contentEditable={true} title="Texte du spost" name="content" id="content" tabIndex={2} data-placeholder={t('add.form.content')} required 
                                            onInput={(content) => {
                                                // Set data
                                                setData({ ...data, content: DOMPurify.sanitize(content.currentTarget.innerHTML.trim(), {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'em', 'ol', 'ul', 'li', 'code', 'a', 'br', 'img'], FORBID_ATTR: ['style']})});
                                                if(content.currentTarget.innerHTML.length >= 2000) {
                                                    setLength({ ...allLength, contentLength: content.currentTarget.innerHTML.length+'/2500' });
                                                }else {
                                                    setLength({ ...allLength, contentLength: '' });
                                                }
                                                // Auto scroll to bottom
                                                content.currentTarget.scrollTop = content.currentTarget.scrollHeight;
                                                // Error UX
                                                if(content.currentTarget.innerHTML.trim() !== "") {
                                                    $('.textSpost_aSPMVB1 #content').css({ borderColor: "#26262C" })
                                                }else{
                                                    $('.textSpost_aSPMVB1 #content').css({ borderColor: "#db4e4e" })
                                                }
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();

                                                const clipboardData = e.clipboardData || window.clipboardData;
                                                const items = clipboardData.items;

                                                if (items) {
                                                    for (let i = 0; i < items.length; i++) {
                                                        const item = items[i];
                                                        if (item.type.indexOf("image") !== -1) return;
                                                    }
                                                }

                                                const text = clipboardData.getData('text');
                                                document.execCommand('insertText', false, text);
                                            }}
                                        />
                                        <div className="TextareaProtectValues">
                                            <span id="charactersCount_content">{allLength.contentLength}</span>
                                            {
                                                (claimed.includes(0) || claimed.includes(2) || claimed.includes(3) || claimed.includes(4) || claimed.includes(5)) && (
                                                <div className="emojiPicker_textSpost" data-container="emojiPicker" onClick={openNewMenu}>
                                                    <i className="fi fi-sr-smile" data-container="emojiPicker" style={{display: 'flex'}}></i>
                                                </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="mediaSpost_aSPMVB1">
                                        <label className="chooseFile_mSMVB1" htmlFor="media">
                                            <span>
                                                <label id="mediaText" htmlFor="media">{t('add.form.media.description')}</label>
                                            </span>
                                            <label id="button" htmlFor="media" tabIndex={3}>{t('add.form.media.button')}</label>
                                        </label>
                                        <input title="Média du spost" name="media" id="media" type="file" accept="video/*,image/*,audio/*,application/pdf,application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain" style={{display: 'none'}}
                                            onChange={(media) => setFiles(media.target.files)}
                                        />
                                    </div>
                                    <div className="tagsSpost_aSPMVB1">
                                        <div id="nsfw" tabIndex={4} onClick={() => selectTag('nsfw')} title={i18n.resolvedLanguage === "fr" ? "Ajouter ce tag": "Add this tag"}>NSFW (+18)</div>
                                        <div id="popular" tabIndex={5} onClick={() => selectTag('popular')} title={i18n.resolvedLanguage === "fr" ? "Ajouter ce tag": "Add this tag"}>{t('add.form.tags.popular')}</div>
                                        <div id="advertising" tabIndex={6} onClick={() => selectTag('advertising')} title={i18n.resolvedLanguage === "fr" ? "Ajouter ce tag": "Add this tag"}>{t('add.form.tags.advertising')}</div>
                                        <div id="news" tabIndex={7} onClick={() => selectTag('news')} title={i18n.resolvedLanguage === "fr" ? "Ajouter ce tag": "Add this tag"}>{t('add.form.tags.news')}</div>
                                        <div id="add" tabIndex={8} onClick={showAddTag} style={{ color: "#9A9A9A", width: 50 }} title={i18n.resolvedLanguage === "fr" ? "Ajouter un tag": "Add a tag"}>
                                            <i className="fi fi-rr-plus" style={{ display: "flex", height: "fit-content" }}></i>
                                        </div>
                                    </div>
                                    <div className="searchTagSpost_aSPMVB1" style={{ display: "none" }}>
                                        <input title="Ajouter un tag" name="tagAdd" id="tagAdd" placeholder="Ajouter un tag..." maxLength="20" tabIndex={9} type="text"
                                            onChange={(add) => {
                                                setData({ ...data, add: add.target.value.trim() });
                                                setLength({ ...allLength, addLength: add.target.value.length })
                                            }}
                                            onKeyUp={(pressed) => {
                                                if(pressed.key === "Enter"){
                                                    if(allLength.addLength <= 20){
                                                        var value = data.add.replace(/[.?!:;,$€£¤¨^µ*%ù"'^@-]/g, '');
                                                            value.replace(' ', '_')
                                                            value = DOMPurify.sanitize(value, {USE_PROFILES: {html: false, svg: false, mathMl: false}});
                                                        // Verify value is not null
                                                        if(value !== ""){
                                                            addTag(value);
                                                            tags.push(value);
                                                        }
                                                    }else{
                                                        setError('A tag is limited to 20 characters.')
                                                        if(i18n.resolvedLanguage === "fr"){
                                                            setError('Un tag est limité à 20 caractères.')
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                        <span id="charactersCount_search">{allLength.addLength}/20</span>
                                    </div>
                                </form>
                                <div className={"separator_addSpost "+theme}></div>
                                {(claimed.includes(7) && !promotedStatus) && (
                                    <div className="options_aSPPcVB1">
                                        <span>{t('add.promote')}</span>
                                        <div id="betaContainer">
                                            <span>BETA</span>
                                        </div>
                                        <input type="checkbox" id="promote" name="promote" onChange={(e) => {
                                            if(e.currentTarget.checked){
                                                setPromoted('Yes');
                                            }else{
                                                setPromoted('No');
                                            }
                                        }} />
                                        <label htmlFor="promote" style={{cursor: "pointer"}}></label>
                                    </div>
                                )}
                                <div className="buttonsAction_aSPMVB1">
                                    <div id="cancel" tabIndex={11} onClick={() => {
                                        if(searchParams.get('rel')){
                                            navigate(searchParams.get('rel'))
                                        }else{
                                            navigate('/')
                                        }
                                    }}>
                                        {t('general.buttons.cancel')}
                                    </div>
                                    <div id="submit" tabIndex={10} onClick={publish}>{t('add.submit')}</div>
                                    <p id="infoPosting" className={theme}>
                                        <i className="fi fi-rr-info"></i>
                                        <span>
                                            {t('add.footer')} <a href={"https://about.snot.fr/"+i18n.resolvedLanguage+"/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')} target="_blank" rel="noreferrer">{t('footer.content')}</a>.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </main>
                    </div>
                    <div className="streakInfo_PcVB1 slide-in-bottom">
                        {
                            !logs.message ?
                                lastStreak === "not_done_finished" ?
                                <div className={"streakInfo_containerMVB1 "+theme} style={{ width: "auto", paddingRight: 20 }}>
                                    <img src={emojis_nature.fire} alt="Streak icon" title="Streak - Turn it on!" />
                                    <p>
                                        <span className="medium">{t('streak.activate.medium.3')}</span> {t('streak.activate.normal.3')}
                                    </p>
                                </div>
                                :
                                <div className={"streakInfo_containerMVB1 "+theme} style={{ width: "auto", paddingRight: 20 }}>
                                    <img src={emojis_nature.fire} alt="Streak icon" title="Streak - Maintain it!" />
                                    <p>
                                        <span className="medium">{t('streak.continue.medium.1')}</span> {t('streak.continue.normal.1')}
                                    </p>
                                </div>
                            :
                            <div className={"streakInfo_containerMVB1 "+theme}>
                                <img src={emojis_nature.fire} alt="Streak icon" title="Streak - Activate it!" style={{ marginLeft: 20, marginRight: 15, width: 40, height: 40 }} />
                                <p>
                                    <span className="medium">{t('streak.activate.medium.1')}</span> {t('streak.activate.normal.1')}<br />
                                    <span className="medium">{t('streak.activate.medium.2')}</span> {t('streak.activate.normal.2')}
                                </p>
                            </div>
                        }
                    </div>
                    <EmojiPicker claimed={claimed} />
                </>
            }
        </>
    );
}
 
export default Add;