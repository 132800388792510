import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from '../../Functions';

const CreateGroup = ({user, theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [friends, setFriends] = useState([]);
    const [name, setName] = useState('');
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [search, setSearch] = useState('');
    const [listLive, setLLive] = useState(<></>);
    const [error, setError] = useState('');
    // Set loading
    const [loading, setLoading] = useState(true);
    const [loadingSearch, setLoadingSearch] = useState(true);
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    // Friends list
    const FriendsList = () => {
        useEffect(() => {
            friends.map((response) => {
                $('.friend_cGrPcVB1.create.ref_'+response.uid).off('click').on('click', () => selectFriend(response.uid));
            })
        }, [friends, search, listLive]);
        
        if(friends && user.length !== 0){
            setLoading(false);
            if(search === ''){
                return(
                    <>
                        {friends.map((response) => (
                            <div className={"friend_cGrPcVB1 create ref_"+response.uid} style={{ alignItems: 'center' }}>
                                <div id="profilePart">
                                    <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                        <img alt={"@"+response.usertag.replace('.', '_').toLowerCase()} title={response.username} src={response.avatar} 
                                            onError={(e) => {
                                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                    e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                                }
                                            }}
                                            style={loadingAvatar ? {display: "none"}: {}}
                                            onLoad={() => setLoadingAvatar(false)}
                                            referrerPolicy="no-referrer"
                                        />
                                        <div id="presence" style={{ width: "100%", marginLeft: 2, marginTop: -18 }}>
                                            <div className={"presence_presencePPartMVB1 user_"+response.uid} style={{ outline: "3px solid #101010", border: "2px solid transparent", width: 10, height: 10 }}></div>
                                        </div>
                                    </div>
                                    <div className="user" style={{ marginLeft: 15 }}><span id="username">{response.username}</span></div>
                                    <div className="usertag"><span id="usertag">@{response.usertag.replace('.', '_').toLowerCase()}</span></div>
                                </div>
                                <i className='fi fi-rr-plus' style={{ display: 'flex', margin: '0 5px 0 auto' }}></i>
                            </div>
                        ))}
                    </>
                );
            }else{
                if(loadingSearch === true){
                    return (<>
                        <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto",fontSize: 14}}>
                            <i className="fi fi-rr-search" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center",color: theme === "darkTheme" ? "#9A9A9A":"#707070"}}></i>
                            <span className="texton_frPFPB1" style={{display: "flex",marginTop: 15,justifyContent: "center",fontSize: 24}}>{t('profile.search.title')}</span>
                            {t('profile.search.description')}
                        </p>
                    </>)
                }else{
                    return listLive;
                }
            }
        }else{
            return(
                <>
                <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto",fontSize: 14}}>
                    <i className="fi fi-rr-interrogation" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center",color: theme === "darkTheme" ? "#9A9A9A":"#707070"}}></i>
                    <span className="texton_frPFPB1" style={{display: "flex",marginTop: 15,justifyContent: "center", fontSize: 24}}>{t('profile.error.noFollowersSubs.title')}</span>
                    {t('profile.error.noResults.description')}
                </p>
                </>
            );
        }
    }

    // -- Add picture
    const fileTypes = [
        "image/apng",
        "image/x-bmp",
        "image/bmp",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "image/tiff",
        "image/x-icon",
        "image/avif",
        "image/svg+xml",
        "image/webp",
        "image/heif",
        "image/heif-sequence",
        "image/heic",
        "image/heic-sequence",
        "image/jp2",
        "image/jpx",
        "image/jpm",
        "image/avci",
        "image/aces",
        "image/gif",
    ];

    function validFileType(file) {
        return fileTypes.includes(file.type);
    }

    const filePreview = (e) => {
        if(window.File && window.FileList && window.FileReader) {
            var file = e.target.files[0];
            var groupPicture = document.querySelector('.addPicture_gPcVB1');
    
            if(validFileType(file)){
                if (file.size <= 6291456) {
                    var preview = URL.createObjectURL(file);
                    localStorage.setItem('group_picture', preview);
                    // UI
                    $('#addIcon').css('display', 'none');
                    $('#pictureIcon').css('display', 'none');
                    var pic = document.getElementById('previewPic');
                        pic.src = preview;
                        pic.style.display = "flex";
                        pic.style.background = theme === "darkTheme" ? "#101010": "#E3E3E3";
                    // -- -- Append 'img'
                    groupPicture.appendChild(pic, null);
                }else {
                    setError('Your picture can\'t exceed 10MB.');
                }     
            }else{
                setError('You must upload an image.');
            }
        }else {
            console.log("Your browser does not support File API");
        }
    }

    // -- Select friend
    const selectFriend = (ref) => {
        var card = $('.friend_cGrPcVB1.ref_'+ref);
        var icon = $('.friend_cGrPcVB1.ref_'+ref+' > i');
        // UI / UX
        if(icon.hasClass("fi-rr-plus")) {
            icon.removeClass("fi-rr-plus").addClass("fi-rr-check");
            if(!card.hasClass('selected')) card.addClass('selected');
            // Append to list
            selectedMembers.push(ref);
        }else{
            icon.removeClass("fi-rr-plus").addClass("fi fi-rr-plus");
            if(card.hasClass('selected')) card.removeClass('selected');
            // Remove from list
            var index = selectedMembers.indexOf(ref);
            if (index > -1) {
                selectedMembers.splice(index, 1);
            }
        }
    }

    // -- Create group
    const createGroup = () => {
        if(selectedMembers.length >= 2){
            // Max amount of friends selected
            if(selectedMembers.length >= 9) setError(t('groups.create.modal.errors.friends.max'));

            // Set loading
            setError(t('groups.create.modal.loading'));
            $("#createGroup .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");

            // Send request
            axios.post(
                `https://api.snot.fr/v${API_VERSION}/group/${user.uid}`,
                { members: selectedMembers, name: name },
                {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            ).then(response => {
                if(response.data.success === true){
                    var picture = localStorage.getItem('group_picture');
                    if(picture){
                        // Create file object
                        var fileObject = fetch(picture)
                        .then(r => r.blob())
                        .then(blob => {
                            fileObject = new File([blob], response.data.key+'.webp', {type: 'image/webp'});
                            // ...
                            const formData = new FormData();
                            formData.append("image", fileObject, response.data.key+'.webp');
                            // UPLOAD
                            axios.post(`https://api.snot.fr/v${API_VERSION}/upload/groups`, formData, {
                                params: {
                                    token: Cookies.get('loginToken'),
                                    uid: user.uid,
                                    app: 'web'
                                }
                            })
                            .then(resUp => {
                                if(resUp.data.message === "SUCCESS_UPLOAD"){
                                    axios.put(
                                        `https://api.snot.fr/v${API_VERSION}/group/picture/edit/${response.data.key}`,
                                        { value: 'https://api.snot.fr/v1/group/picture/'+response.data.key },
                                        {
                                            headers: { "Content-Type": "application/json" },
                                            params: {
                                                token: Cookies.get('loginToken'),
                                                uid: user.uid,
                                                app: 'web'
                                            }
                                        }
                                    ).then(responsePic => {
                                        if (responsePic.data.success) {
                                            navigate('/group/'+response.data.key);
                                        }
                                    });
                                }
                            })
                        }) 
                    }else{
                        navigate('/group/'+response.data.key);
                    }
                }
            })
        }else {
            setError(t('groups.create.modal.errors.friends.min'));
        }
    }

    // -- AXIOS
    // GET ALL FRIENDS
    const getFriends = (user) => {
        return new Promise((resolve, reject) => {
            axios.get(
                `https://api.snot.fr/v${API_VERSION}/user/${user}/friends`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        app: 'web'
                    }
                }
            )
            .then((res) => resolve(res.data.friends))
            .catch((err) => reject(err))
        })
    }

    /* FOR SEARCH FUNCTIONS */
    const searchFriends = (query) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'GET',
                url: `https://api.snot.fr/v${API_VERSION}/user/${user.uid}/search/friends/?query=${query}`,
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }).then((response) => {
                resolve(response.data.search);
            }).catch((error) => resolve(0))
        })
    }

    useEffect(() => {
        if(search !== ''){
            searchFriends(search).then((results) => {
                if(results !== 0){
                    results.map((response) => {
                        setLLive(
                            <>
                            <div className={"friend_cGrPcVB1 ref_"+response.uid} style={{ alignItems: 'center' }}>
                                <div id="profilePart">
                                    <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                        <img alt={"@"+response.usertag.replace('.', '_').toLowerCase()} title={response.username} src={response.avatar} 
                                            onError={(e) => {
                                                if(!e.currentTarget.src.includes('.googleusercontent.com')){
                                                    e.currentTarget.src = 'https://api.snot.fr/v'+API_VERSION+'/content/icon_profile?ext=webp';
                                                }
                                            }}
                                            style={loadingAvatar ? {display: "none"}: {}}
                                            onLoad={() => setLoadingAvatar(false)}
                                            referrerPolicy="no-referrer"
                                        />
                                        <div id="presence" style={{ width: "100%", marginLeft: 2, marginTop: -18 }}>
                                            <div className={"presence_presencePPartMVB1 user_"+response.uid} style={{ outline: "3px solid #101010", border: "2px solid transparent", width: 10, height: 10 }}></div>
                                        </div>
                                    </div>
                                    <div className="user" style={{ marginLeft: 15 }}><span id="username">{response.username}</span></div>
                                    <div className="usertag"><span id="usertag">@{response.usertag.replace('.', '_').toLowerCase()}</span></div>
                                </div>
                                <i className='fi fi-rr-plus' style={{ display: 'flex', margin: '0 5px 0 auto' }}></i>
                            </div>
                            </>
                        );
                    })
                }else{
                    setLLive(
                        <>
                        <p className="text_frFPB1" style={{textAlign: "center", width: 350, margin: "20px auto",fontSize: 14}}>
                            <i className="fi fi-rr-interrogation" style={{display: "flex",alignItems: "center",height:"fit-content",fontSize:36,justifyContent: "center",color: theme === "darkTheme" ? "#9A9A9A":"#707070"}}></i>
                            <span className="texton_frPFPB1" style={{display: "flex",marginTop: 15,justifyContent: "center", fontSize: 24}}>{t('profile.error.noFollowersSubs.title')}</span>
                            {t('profile.error.noResults.description')}
                        </p>
                        </>
                    );
                }
                // Set loading
                setLoadingSearch(false);
            })
        }

        // EVENT LISTENERS
        if(friends !== null && friends.length !== 0){
            friends.map((result) => {
                $('.ref_'+result.uid).off('click').on('click', () => selectFriend(result.usertag, result.uid));
            })
        }
    }, [search, user, friends])
    /* END OF SEARCH FUNCTIONS */

    useEffect(() => {
        // Fetch all friends
        getFriends(user.uid).then((resFriends) => {
            setFriends(resFriends)
        });

        // EVENTS
        $('.addPicture_gPcVB1 #picture').off('change').on('change', filePreview);
        // ACTIONS BUTTON (for customization)
        $('.actions_gPcVB1 #cancel').off('click').on('click', () => {
            // -- Picture
            $('.addPicture_gPcVB1 #previewPic').removeAttr('src').css('display', 'none');
            $('#addIcon').css('display', 'flex');
            $('#pictureIcon').css('display', 'flex');
            // -- Name
            $('.groupName_gPcVB1 #name').val('');
            // -- Storage
            localStorage.removeItem('group_picture');
            localStorage.removeItem('group_name');
        });
        $('.actions_gPcVB1 #create.next').off('click').on('click', () => {
            $('#customizeGroup').css('display', 'none');
            $('#addMembers').css('display', 'block');
        });
        $('.actions_gPcVB1 #go-back').off('click').on('click', () => {
            $('#addMembers').css('display', 'none');
            $('#customizeGroup').css('display', 'block');
        });
    }, [])

    useEffect(() => {
        selectedMembers.map(res => {
            selectFriend(res);
        })
    }, [error])

    useEffect(() => {
        $('.actions_gPcVB1 #create.create').off('click').on('click', createGroup);
    }, [name, selectedMembers, error])

    return (
        <>
        <aside id="createGroup" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='scrollableFriends' className="modal-wrapper newColors js-modal-stop" style={{padding: 25,width: 400,maxHeight: 550}}>
                {/* FIRST STEP */}
                <section id='customizeGroup'>
                    <div className="topPart_groupPcVB1">
                        <h1>{t('groups.create.button')}</h1>
                        <h2>
                            {t('groups.create.subtitle.1')}<br/>
                            {t('groups.create.subtitle.2')}
                        </h2>
                    </div>
                    <div className="mainPart_fSLMPcB1" style={{marginTop: 50}}>
                        <form method='POST' encType="multipart/form-data" style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                            <label className="addPicture_gPcVB1" tabIndex={1} htmlFor="picture">
                                <div id="addIcon">
                                    <i className="fi fi-rr-plus"></i>
                                </div>
                                <i id="pictureIcon" className="fi fi-rr-picture"></i>
                                <input id="picture" name="picture" type="file" accept="image/*" style={{ display: 'none' }} />
                                <img id='previewPic' alt={t('groups.create.customPart.picture')} title={t('groups.create.customPart.picture')} referrerPolicy="no-referrer" style={{display: 'none'}} />
                            </label>
                            <div className="groupName_gPcVB1">
                                <label>{t('groups.create.modal.customPart.name')}</label>
                                <input type="text" name="name" id="name" autoComplete="name" placeholder="TeamA" maxLength={25} minLength={3} tabIndex={2}
                                    onChange={(e) => {
                                        var nameValue = e.currentTarget.value.trim();
                                        // MIN LENGTH
                                        if(nameValue.length > 0 && nameValue.length < 3){
                                            setError(t('groups.create.errors.name.min'));
                                        }
                                        // MAX LENGTH
                                        else if(nameValue.length > 25){
                                            setError(t('groups.create.errors.name.max'));
                                        }
                                        // NORMAL LENGTH
                                        else {
                                            setError('');
                                            setName(DOMPurify.sanitize(nameValue, {USE_PROFILES: {html: false, svg: false, mathMl: false}}));
                                        }
                                    }} />
                            </div>
                        </form>
                        <div className="actions_gPcVB1">
                            <div id="cancel" className='js-modal-close-other' tabIndex={4}>
                                <i className="fi fi-rr-cross"></i>
                                <span>{t('groups.modals.cancel')}</span>
                            </div>
                            <div id="create" className='next' tabIndex={3}>
                                <i className="fi fi-rr-check"></i>
                                <span>{t('groups.modals.next')}</span>
                            </div>
                        </div>
                        <p className="note_gPcVB1">{t('groups.create.modal.customPart.note')}</p>
                    </div>
                </section>
                {/* SECOND STEP */}
                <section id='addMembers' style={{display: 'none'}}>
                    <div className="topPart_groupPcVB1">
                        <h1>{t('groups.create.button')}</h1>
                        <h2>
                            {t('groups.addMembers.subtitle.1')}<br/>
                            {t('groups.addMembers.subtitle.2')}
                        </h2>
                    </div>
                    <div className="mainPart_fSLMPcB1" style={{marginTop: 25}}>
                        <p className='error' style={{ margin: 0, color: 'rgb(255, 99, 99)', fontSize: 14, marginBottom: error !== '' && 15 }}>{error}</p>
                        <div className="searchFriend_cGrPcVB1">
                            <input type="search" name="search" id="search" placeholder={t('groups.modals.search')} onChange={(e) => setSearch(DOMPurify.sanitize(e.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}))} />
                            <i className="fi fi-rr-search"></i>
                        </div>
                        <FriendsList />
                        <div className="stickyBottom_aGPcVB1">
                            <div className="actions_gPcVB1">
                                <div id="go-back" tabIndex={4}>
                                    <i className="fi fi-rr-arrow-left"></i>
                                    <span>{t('groups.modals.goBack')}</span>
                                </div>
                                <div id="create" className='create' tabIndex={3}>
                                    <i className="fi fi-rr-check"></i>
                                    <span>{t('groups.create.modal.button')}</span>
                                </div>
                            </div>
                            <p className="note_gPcVB1">{t('groups.modals.membersLimit')}</p>
                        </div>
                    </div>
                </section>
            </div>
        </aside>
        </>
    )
}

export default CreateGroup;