import {useCallback, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import $ from 'jquery';
// import i18next
import { useTranslation, Trans } from 'react-i18next';
import i18n from "../../i18n/i18n";
// import Components
import {LoadingSuggestions} from '../Loading/LoadingSuggestions';
// import Hooks
import { handleImageError } from '../../Hooks/handleAvatarError';
// import Functions
import { API_VERSION } from '../Functions';
// import Auth
import { retreiveInfos } from '../Functions/Auth';

const ucFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const TrendsContainer = ({theme}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set req limit
    const [req, setReq] = useState(0);
    const [limit, setLimit] = useState(3);
    // Set data
    const [data, setData] = useState([]);
    const [dataSuggest, setDataSuggest] = useState([]);
    const [infos, setInfos] = useState([]);
    // Set loading
    const [loadingAvatar, setLoadingAvatar] = useState(true);
    const [loading, setLoading] = useState(true);

    // -- Set logged
    const [logged, setLogged] = useState(0);
    useEffect(() => {
        if(Cookies.get('logged') === '1'){
            setLogged(1);
        }
    }, [])
    
    // GET TRENDY TAGS
    const getTrendyThemes = async () => {
        try {
            const trends = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/trends`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        theme: theme.replace('Theme', ''),
                        lang: i18n.resolvedLanguage,
                    }
                }
            );
            return trends.data.result;
        } catch (error) {
            console.error('Error fetching trends:', error);
            return null;
        }
    }

    // GET SUGGESTED ACCOUNTS
    const getSuggestions = async (uid, req=0, limit=3) => {
        try {
            const suggest = await axios.get(
                `https://api.snot.fr/v${API_VERSION}/suggested/accounts`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: uid,
                        app: 'web',
                        req: req, // Default is 0
                        limit: limit // Default limit is 3
                    }
                }
            );
            return suggest.data; // Return the suggestions data
        } catch (error) {
            console.error('Error fetching suggested accounts:', error);
            return null;
        }
    }

    /* -------------
     * TRENDS THEMES
     * -------------
     */
    const Trends = () => {
        if(data === null || data === undefined){
            return (
                <div className="trendContainer_mDrawerMVB1" style={{ height: 150 }}>
                    <p className="text_frFPB1">
                        <span className="texton_frPFPB1">Ah...</span><br/>
                        <Trans i18nKey={'general.trends.error.description'}></Trans>
                    </p>
                </div>
            )
        }else{
            return (<>
                {data.map(trendy => {
                    // Traduction
                    var trendName = trendy.tag;
                    if(i18n.resolvedLanguage === "fr"){
                        if(trendName === "popular") trendName = "populaire";
                        else if(trendName === "news") trendName = "actualités";
                        else if(trendName === "advertising") trendName = "publicité";
                    }

                    return (
                        <div className="trendContainer_mDrawerMVB1" onClick={() => navigate('/tags?t='+trendy.tag)}>
                            <p className="titleContainer_tCMVB1">#{ucFirst(trendName)}</p>
                            <span className='stats_tCMVB1'>{trendy.count || 0} post{trendy.count > 1 ? 's': ''}</span>
                        </div>
                    )
                })}
            </>)
        }
    }

    /* ------------------
     * SUGGESTED ACCOUNTS
     * ------------------
     */
    const LoadingSuggests = () => {
        return (
            <>
            <LoadingSuggestions theme={theme} />
            <LoadingSuggestions theme={theme} />
            <LoadingSuggestions theme={theme} />
            </>
        )
    }

    const SuggestedAccounts = () => {
        if(dataSuggest !== null || dataSuggest !== undefined){
            return (<>
                {dataSuggest.map(suggest => {
                    return (
                        <div key={suggest.uid} className={"accountPre_sRlBPcVB1 ref_"+suggest.uid} onClick={() => navigate('/@'+suggest.usertag.replace('.', '_').toLowerCase())}>
                            <div id="profile">
                                <div className={loadingAvatar ? "avatar skeleton "+theme: "avatar "+theme }>
                                    <img alt={"@"+suggest.usertag.replace('.', '_').toLowerCase()} title={suggest.username} src={suggest.avatar}
                                        onError={handleImageError}
                                        style={loadingAvatar ? {display: "none"}: {}}
                                        onLoad={() => setLoadingAvatar(false)}
                                        referrerPolicy="no-referrer"
                                    />
                                </div>
                                <div id="user">
                                    <span id="username">{suggest.username} {
                                        (suggest.badges && suggest.badges !== null) && JSON.parse(suggest.badges).map((badge, index) => {
                                            let icon = badge.icon;
                                            if(badge.icon.dark !== undefined || badge.icon.light !== undefined){
                                                if(theme === "darkTheme"){
                                                    icon = badge.icon.dark;
                                                }else{
                                                    icon = badge.icon.light;
                                                }
                                            }

                                            return <div dangerouslySetInnerHTML={{__html: icon }} />;
                                        })
                                    }</span>
                                    <span id="usertag">@{suggest.usertag.toLowerCase().replace('.', '_')}</span>
                                </div>
                            </div>
                            <div id="follow" onClick={(e) => {
                                e.stopPropagation();
                                interact(suggest.uid, 'sub');
                            }}>{t('general.suggested.follow')}</div>
                        </div>
                    )
                })}
            </>)
        }else{
            return (
                <div className="accountPre_sRlBPcVB1" id='no-hover'>
                    <div id="profile">
                        <div className={"avatar skeleton "+theme} style={{width: 45, height: 45}}></div>
                        <div id="user" style={{ justifyContent: 'center' }}>
                            <span id="username" className={'user skeleton '+theme} style={{ width: 75, height: 18, marginBottom: 5 }}></span>
                            <span id="usertag" className={'user skeleton '+theme} style={{ width: 100, height: 15 }}></span>
                        </div>
                    </div>
                    <div id="follow">{t('general.suggested.follow')}</div>
                </div>
            )
        }
    }

    useEffect(() =>{
        const fetchData = async () => {
            try {
                const infos = await retreiveInfos();
                const trendsPromise = getTrendyThemes();
                const suggestionsPromise = logged === 1 ? getSuggestions(infos.uid) : null;
    
                const [trends, suggestions] = await Promise.all([trendsPromise, suggestionsPromise]);
                if (suggestions) {
                    setDataSuggest(suggestions);
                }
                setData(trends);
                setInfos(infos);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [logged])

    // FUNCTION
    const hideShowLateralBar = useCallback(() => {
        var lateralBar = $('.trendMenu_pcverSB1');
        if(!lateralBar.hasClass('slide-out-right')){
            lateralBar.removeClass('slide-in-right').addClass('slide-out-right');
            $('#hide-show').html(`
                  <svg width="22" height="100" viewBox="0 0 22 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.55822e-09 30C6.55822e-09 12.5 22 0 22 0V100C22 100 6.55837e-09 90 6.55822e-09 69V30Z" class='background'/>
                    <path d="M15.8377 60L7.72202 51.7663C7.25971 51.2972 7 50.6609 7 49.9975C7 49.3341 7.25971 48.6978 7.72202 48.2287L15.8328 40L16.9951 41.1792L8.88433 49.4079C8.73023 49.5643 8.64366 49.7764 8.64366 49.9975C8.64366 50.2186 8.73023 50.4307 8.88433 50.5871L17 58.8208L15.8377 60Z" class='arrow'/>
                </svg>  
            `);
        }else{
            lateralBar.removeClass('slide-out-right').addClass('slide-in-right');
            $('#hide-show').html(`
                <svg width="22" height="100" viewBox="0 0 22 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.55822e-09 30C6.55822e-09 12.5 22 0 22 0V100C22 100 6.55837e-09 90 6.55822e-09 69V30Z" class='background'/>
                    <path d="M8.16232 40L16.278 48.2337C16.7403 48.7028 17 49.3391 17 50.0025C17 50.6659 16.7403 51.3022 16.278 51.7713L8.16725 60L7.00493 58.8208L15.1157 50.5921C15.2698 50.4357 15.3563 50.2236 15.3563 50.0025C15.3563 49.7814 15.2698 49.5693 15.1157 49.4129L7 41.1792L8.16232 40Z" class='arrow'/>
                </svg>
            `);
        }
    }, [theme]);

    const hideShowBlock = useCallback((block) => {
        var trends = $('.trends_fBmVeB1');
        var suggests = $('.suggestions_rlBPcVB1');

        if(block === "trends"){
            if(!trends.hasClass('hide')){
                trends.css('height', 0).removeClass('show').addClass('hide');
                $('#hide-show-trends').removeClass('fi-rr-angle-down').addClass('fi-rr-angle-up');
            }else{
                trends.css('height', 'auto').removeClass('hide').addClass('show');
                $('#hide-show-trends').removeClass('fi-rr-angle-up').addClass('fi-rr-angle-down');
            }
        }else{
            if(!suggests.hasClass('hide')){
                suggests.css('height', 0).removeClass('show').addClass('hide');
                $('#hide-show-suggests').removeClass('fi-rr-angle-down').addClass('fi-rr-angle-up');
            }else{
                suggests.css('height', 'auto').removeClass('hide').addClass('show');
                $('#hide-show-suggests').removeClass('fi-rr-angle-up').addClass('fi-rr-angle-down');
            }
        }
    }, [theme]);

    const interact = useCallback(async (uid, type) => {
        const button = $('.accountPre_sRlBPcVB1.ref_'+uid+' #follow');

        if(type === "sub"){
            // UX
            button.off('click').on('click', (e) => {
                e.stopPropagation();
                interact(uid, 'unsub');
            });
            // API call
            await axios.post(
                `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                undefined,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: infos.uid,
                        app: 'web'
                    }
                }
            );
            button.text(t('general.suggested.followed'))
        }else{
            // UI
            button.text(t('general.suggested.follow'));
            // UX
            button.off('click').on('click', (e) => {
                e.stopPropagation();
                interact(uid, 'sub');
            });
            // API call
            await axios.delete(
                `https://api.snot.fr/v${API_VERSION}/user/${uid}/followers`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: infos.uid,
                        app: 'web'
                    }
                }
            );
        }
    }, [infos]);

    const refreshSuggestions = useCallback(async () => {
        setLoading(true);
        // ...
        var newReq = req + 3;
        var newLimit = limit + 3;
        setReq(newReq);
        setLimit(newLimit);

        const fetchData = async () => {
            try {
                const suggestionsPromise = getSuggestions(infos.uid, newReq, newLimit);
    
                const [suggestions] = await Promise.all([suggestionsPromise]);
                if (suggestions) {
                    setDataSuggest(suggestions);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [req, limit, infos]);

    return (
        <>
        <section className={"trendMenu_pcverSB1 "+theme}>
            <div id='hide-show' onClick={hideShowLateralBar}>
                <svg width={22} height={100} viewBox="0 0 22 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.55822e-09 30C6.55822e-09 12.5 22 0 22 0V100C22 100 6.55837e-09 90 6.55822e-09 69V30Z" className='background'/>
                    <path d="M8.16232 40L16.278 48.2337C16.7403 48.7028 17 49.3391 17 50.0025C17 50.6659 16.7403 51.3022 16.278 51.7713L8.16725 60L7.00493 58.8208L15.1157 50.5921C15.2698 50.4357 15.3563 50.2236 15.3563 50.0025C15.3563 49.7814 15.2698 49.5693 15.1157 49.4129L7 41.1792L8.16232 40Z" className='arrow'/>
                </svg>
            </div>
            <h1 style={{ display: 'flex', alignItems: 'center' }}>
                {t('general.trends.title')}
                <i id="hide-show-trends" className="fi fi-rr-angle-down hideShow-category" onClick={() => hideShowBlock('trends')}></i>
            </h1>
            <div className={"separator_fBmVeB1 "+theme}></div>
            <div className={"trends_fBmVeB1 "+theme}>
                <Trends />
            </div>
            {
                logged === 1 && (<>
                    <h1 style={{ display: 'flex', alignItems: 'center' }}>
                        {t('general.suggested.title')}
                        <i id="hide-show-suggests" className="fi fi-rr-angle-down hideShow-category" onClick={() => hideShowBlock('suggests')}></i>
                    </h1>
                    <div className="suggestions_rlBPcVB1">
                        {
                            loading ? <LoadingSuggests/>
                            : <SuggestedAccounts />
                        }
                        <div id='refresh' onClick={refreshSuggestions}>
                            <i className='fi fi-rr-refresh'></i>
                            <span>{t('general.suggested.reload')}</span>
                        </div>
                    </div>
                    <div className="separator_fBmVeB1 darkTheme"></div>
                </>)
            }
            <footer className={"legalMentions_tMpVB1 "+theme}>
                <p>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/tos": "https://about.snot.fr/en/tos"} hrefLang={i18n.resolvedLanguage} title={t('footer.tos')}>{t('footer.tos')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/privacy": "https://about.snot.fr/en/privacy"} hrefLang={i18n.resolvedLanguage} title={t('footer.privacy')}>{t('footer.privacy')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/content": "https://about.snot.fr/en/content"} hrefLang={i18n.resolvedLanguage} title={t('footer.content')}>{t('footer.content')}</a><br/>
                    <a href={i18n.resolvedLanguage === "fr" ? "https://about.snot.fr/fr/branding": "https://about.snot.fr/en/branding"} hrefLang={i18n.resolvedLanguage} title={t('footer.branding')}>{t('footer.branding')}</a><br/><br/>
                    SNOT © { new Date().getFullYear() }.
                </p>
            </footer>
        </section>
        </>
    )
}

export default TrendsContainer;