import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';
import $ from 'jquery';
// import i18next
import { useTranslation } from "react-i18next";
// import Functions
import { API_VERSION } from '../../Functions';

const AddMembers = ({ user, members, pKey, theme }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Set data
    const [friends, setFriends] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [search, setSearch] = useState('');
    const [listLive, setLLive] = useState(<></>);
    const [error, setError] = useState('');
    // Set loading
    const [loadingSearch, setLoadingSearch] = useState(true);
    const [loadingAvatar, setLoadingAvatar] = useState(true);

    const FriendsList = () => {
        useEffect(() => {
            friends.map((response) => {
                if(JSON.parse(members).indexOf(response.uid) === -1){
                    $('.friend_cGrPcVB1.add.ref_'+response.uid).off('click').on('click', () => selectFriend(response.uid));
                }
            })
        }, [friends, search, listLive]);

        if (friends && user.length !== 0) {
            if (search === '') {
                const friendsListMap = friends.map(res => res.uid);
                const membersList = JSON.parse(members);
                membersList.splice(user.uid, 1);

                if (JSON.stringify(membersList) === JSON.stringify(friendsListMap)) {
                    return (
                        <p className="text_frFPB1" style={{ textAlign: "center", width: 350, margin: "20px auto", fontSize: 14 }}>
                            <i className="fi fi-rr-interrogation" style={{ display: "flex", alignItems: "center", height: "fit-content", fontSize: 36, justifyContent: "center", color: theme === "darkTheme" ? "#9A9A9A" : "#707070" }}></i>
                            <span className="texton_frPFPB1" style={{ display: "flex", marginTop: 15, justifyContent: "center", fontSize: 24 }}>{t('profile.error.noFollowersSubs.title')}</span>
                            {t('profile.error.noResults.description')}
                        </p>
                    );
                } else {
                    return (
                        <>
                            {friends.map((response) => (
                                JSON.parse(members).indexOf(response.uid) === -1 && (
                                    <div className={`friend_cGrPcVB1 add ref_${response.uid}`} style={{ alignItems: 'center' }} key={response.uid}>
                                        <div id="profilePart">
                                            <div className={loadingAvatar ? `avatar skeleton ${theme}` : `avatar ${theme}`}>
                                                <img alt={"@" + response.usertag.replace('.', '_').toLowerCase()} title={response.username} src={response.avatar}
                                                    onError={(e) => {
                                                        if (!e.currentTarget.src.includes('.googleusercontent.com')) {
                                                            e.currentTarget.src = 'https://api.snot.fr/v' + API_VERSION + '/content/icon_profile?ext=webp';
                                                        }
                                                    }}
                                                    style={loadingAvatar ? { display: "none" } : {}}
                                                    onLoad={() => setLoadingAvatar(false)}
                                                    referrerPolicy="no-referrer"
                                                />
                                                <div id="presence" style={{ width: "100%", marginLeft: 2, marginTop: -18 }}>
                                                    <div className={`presence_presencePPartMVB1 user_${response.uid}`} style={{ outline: "3px solid #101010", border: "2px solid transparent", width: 10, height: 10 }}></div>
                                                </div>
                                            </div>
                                            <div className="user" style={{ marginLeft: 15 }}><span id="username">{response.username}</span></div>
                                            <div className="usertag"><span id="usertag">@{response.usertag.replace('.', '_').toLowerCase()}</span></div>
                                        </div>
                                        <i className='fi fi-rr-plus' style={{ display: 'flex', margin: '0 5px 0 auto' }}></i>
                                    </div>
                                )
                            ))}
                        </>
                    );
                }
            } else {
                if (loadingSearch === true) {
                    return (
                        <p className="text_frFPB1" style={{ textAlign: "center", width: 350, margin: "20px auto", fontSize: 14 }}>
                            <i className="fi fi-rr-search" style={{ display: "flex", alignItems: "center", height: "fit-content", fontSize: 36, justifyContent: "center", color: theme === "darkTheme" ? "#9A9A9A" : "#707070" }}></i>
                            <span className="texton_frPFPB1" style={{ display: "flex", marginTop: 15, justifyContent: "center", fontSize: 24 }}>{t('profile.search.title')}</span>
                            {t('profile.search.description')}
                        </p>
                    )
                } else {
                    return listLive;
                }
            }
        } else {
            return (
                <p className="text_frFPB1" style={{ textAlign: "center", width: 350, margin: "20px auto", fontSize: 14 }}>
                    <i className="fi fi-rr-interrogation" style={{ display: "flex", alignItems: "center", height: "fit-content", fontSize: 36, justifyContent: "center", color: theme === "darkTheme" ? "#9A9A9A" : "#707070" }}></i>
                    <span className="texton_frPFPB1" style={{ display: "flex", marginTop: 15, justifyContent: "center", fontSize: 24 }}>{t('profile.error.noFollowersSubs.title')}</span>
                    {t('profile.error.noResults.description')}
                </p>
            );
        }
    };

    const selectFriend = (ref) => {
        var card = $('#addMembers.modal .friend_cGrPcVB1.ref_'+ref);
        var icon = $('#addMembers.modal .friend_cGrPcVB1.ref_'+ref+' > i');
        // UI / UX
        if(icon.hasClass("fi-rr-plus")) {
            icon.removeClass("fi-rr-plus").addClass("fi-rr-check");
            if(!card.hasClass('selected')) card.addClass('selected');
            // Append to list
            selectedMembers.push(ref);
        }else{
            icon.removeClass("fi-rr-plus").addClass("fi fi-rr-plus");
            if(card.hasClass('selected')) card.removeClass('selected');
            // Remove from list
            var index = selectedMembers.indexOf(ref);
            if (index > -1) {
                selectedMembers.splice(index, 1);
            }
        }
    }

    const addMembers = () => {
        if (selectedMembers.length >= 1) {
            if (JSON.parse(members).length + selectedMembers.length > 9) {
                setError(t('groups.create.modal.errors.friends.max'));
                return;
            }

            // Set loading
            $("#addMembers.modal .mainPart_fSLMPcB1 .error").css('color', theme === "darkTheme" ? "#9A9A9A": "#707070");
            setError(t('groups.create.modal.loading'));

            axios.put(
                `https://api.snot.fr/v${API_VERSION}/group/add/members/${pKey}`,
                { addMembers: selectedMembers, members: members },
                {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        token: Cookies.get('loginToken'),
                        uid: user.uid,
                        app: 'web'
                    }
                }
            ).then(response => {
                if (response.data.success) {
                    $('#addMembers.modal').trigger('click'); // Close modal
                }
            }).catch(err => {
                setError('Failed to add members.');
            });
        } else {
            setError(t('groups.addMembers.modal.errors.friends.min'));
        }
    };

    const getFriends = (user) => {
        return axios.get(
            `https://api.snot.fr/v${API_VERSION}/user/${user}/friends`,
            {
                headers: { "Content-Type": "application/json" },
                params: {
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }
        ).then(response => response.data.friends);
    };

    const searchFriends = (query) => {
        return axios.get(
            `https://api.snot.fr/v${API_VERSION}/user/${user.uid}/search/friends`,
            {
                headers: { "Content-Type": "application/json" },
                params: {
                    query: query,
                    token: Cookies.get('loginToken'),
                    app: 'web'
                }
            }
        ).then(response => response.data.search);
    };

    // ! SEARCH FUNCTION !
    useEffect(() => {
        if (search !== '') {
            setLoadingSearch(true);
            searchFriends(search).then((results) => {
                if (results.length) {
                    setLLive(
                        <>
                            {results.map(response => (
                                JSON.parse(members).indexOf(response.uid) === -1 && (
                                    <div className={`friend_cGrPcVB1 add ref_${response.uid}`} style={{ alignItems: 'center' }} key={response.uid}>
                                        <div id="profilePart">
                                            <div className={`avatar ${theme}`}>
                                                <img alt={"@" + response.usertag.replace('.', '_').toLowerCase()} title={response.username} src={response.avatar}
                                                    onError={(e) => {
                                                        if (!e.currentTarget.src.includes('.googleusercontent.com')) {
                                                            e.currentTarget.src = 'https://api.snot.fr/v' + API_VERSION + '/content/icon_profile?ext=webp';
                                                        }
                                                    }}
                                                    referrerPolicy="no-referrer"
                                                />
                                            </div>
                                            <div className="user" style={{ marginLeft: 15 }}><span id="username">{response.username}</span></div>
                                            <div className="usertag"><span id="usertag">@{response.usertag.replace('.', '_').toLowerCase()}</span></div>
                                        </div>
                                        <i className='fi fi-rr-plus' style={{ display: 'flex', margin: '0 5px 0 auto' }}></i>
                                    </div>
                                )
                            ))}
                        </>
                    );
                } else {
                    setLLive(<p>No results found</p>);
                }
                setLoadingSearch(false);
            }).catch(() => {
                setLoadingSearch(false);
            });
        } else {
            setLLive(<></>);
        }
    }, [search, members]);
    // ! SEARCH FUNCTION END !    

    useEffect(() => {
        getFriends(user.uid).then(res => {
            setFriends(res);
        });
    }, []);

    useEffect(() => {
        $('.actions_gPcVB1 #create.add').off('click').on('click', addMembers);
    }, [selectedMembers, error])

    return (
        <>
        <aside id="addMembers" className="modal" aria-hidden="true" aria-modal="false" style={{display:"none"}}>
            <div id='scrollableFriends' className="modal-wrapper newColors js-modal-stop" style={{padding: 25,width: 400,maxHeight: 500}}>
                <div className="topPart_groupPcVB1">
                    <h1>{t('groups.addMembers.button')}</h1>
                    <h2>
                        {t('groups.addMembers.subtitle.1')}<br/>
                        {t('groups.addMembers.subtitle.2')}
                    </h2>
                </div>
                <div className="mainPart_fSLMPcB1" style={{marginTop: 25}}>
                    <p className='error' style={{ margin: 0, color: 'rgb(255, 99, 99)', fontSize: 14, marginBottom: error !== '' && 15 }}>{error}</p>
                    <div className="searchFriend_cGrPcVB1">
                        <input type="search" name="search" id="search" placeholder={t('groups.modals.search')} onChange={(e) => setSearch(DOMPurify.sanitize(e.target.value.trim(), {USE_PROFILES: {html: false, svg: false, mathMl: false}}))} />
                        <i className="fi fi-rr-search"></i>
                    </div>
                    {FriendsList()}
                    <div className="stickyBottom_aGPcVB1">
                        <div className="actions_gPcVB1">
                            <div id="cancel" className='js-modal-close-other' tabIndex={4}>
                                <i className="fi fi-rr-arrow-left"></i>
                                <span>{t('groups.modals.goBack')}</span>
                            </div>
                            <div id="create" className='add' tabIndex={3}>
                                <i className="fi fi-rr-check"></i>
                                <span>{t('groups.addMembers.modal.button')}</span>
                            </div>
                        </div>
                        <p className="note_gPcVB1">{t('groups.create.membersLimit')}</p>
                    </div>
                </div>
            </div>
        </aside>
        </>
    )
}

export default AddMembers;