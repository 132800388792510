import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import $ from 'jquery';
import axios from "axios";
import Cookies from "js-cookie";
// import Components
import { Appbar, Navbar } from '../../../Components/Common';
import { VerticalMenu } from "../../../Components/Pages/Settings";
// import i18next
import i18n from "../../../i18n/i18n";
import { useTranslation, Trans } from "react-i18next";
// import Functions
import { openNewMenu } from "../../../Components/Functions/MenuNew";
import * as Settings from '../../../Components/Functions/Settings';
import { API_VERSION } from "../../../Components/Functions";
// import Auth
import { retreiveInfos } from "../../../Components/Functions/Auth";
// import Images
import SNOTPlusLight from '../../../img/logo/snot_plus_light.png';
import SNOTPlusDark from '../../../img/logo/snot_plus_dark.png';
import SNOTProLight from '../../../img/logo/snot_pro_light.png';
import SNOTProDark from '../../../img/logo/snot_pro_dark.png';

// TIME SINCE
function timeSince(date, {lang: { second, minute, hour, day, month, year }}) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
        if(Math.floor(interval) > 1){
            return Math.floor(interval) + " " + year + 's';
        }else{
            return Math.floor(interval) + " " + year;
        }
    }
    interval = seconds / 2592000;

    if (interval > 1) {
        if(Math.floor(interval) > 1 && month !== 'mois'){
            return Math.floor(interval) + " " + month + 's';
        }else{
            return Math.floor(interval) + " " + month;
        }
    }
    interval = seconds / 86400;
    if (interval > 1) {
        if(Math.floor(interval) > 1){
            return Math.floor(interval) + " " + day + 's';
        }else{
            return Math.floor(interval) + " " + day;
        }
    }
    interval = seconds / 3600;
    if (interval > 1) {
        if(Math.floor(interval) > 1){
            return Math.floor(interval) + " " + hour + 's';
        }else{
            return Math.floor(interval) + " " + hour;
        }
    }
    interval = seconds / 60;
    if (interval > 1) {
        if(Math.floor(interval) > 1){
            return Math.floor(interval) + " " + minute + 's';
        }else{
            return Math.floor(interval) + " " + minute;
        }
    }

    if(Math.floor(interval) > 1){
        return Math.floor(interval) + " " + second + 's';
    }else{
        return Math.floor(interval) + " " + second;
    }
}

// TIME SINCE (Japanese)
function timeSinceJP(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + "年前";
    }
    interval = seconds / 2592000;

    if (interval > 1) {
      return Math.floor(interval) + "ヶ月前";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "日前";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "時間前";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "分前";
    }
    return Math.floor(seconds) + "秒前";
}

const SettingsBilling = () => {
    const { t } = useTranslation();

    // Set theme
    const [theme, setTheme] = useState('darkTheme');
    // Set data
    const [infos, setInfos] = useState([]);
    const [billInfo, setBillInfo] = useState([]);

    // -- VERIFY [user] IS LOGGED (OR NOT)
    useEffect(() => {
        if(Cookies.get('logged') !== '1'){
            window.location.replace('/login');
        }
    }, [])
    

    // Format date
    const [dateSince, setDateSince] = useState(null);
    
    useEffect(() => {
        if(billInfo !== null){
            billInfo.map((bill) => {
                const subDate = new Date(bill.date);
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                const match_date = new Date(bill.date);
                match_date.setHours(0, 0, 0, 0);

                const diff = Math.floor((today - match_date) / (1000 * 60 * 60 * 24));
                let diffDays = parseInt(diff);

                if(i18n.resolvedLanguage === "fr"){
                    switch (diffDays) {
                        default:
                            setDateSince("il y a " + timeSince(subDate, {lang: { second: "seconde", minute: 'minute', hour: 'heure', day: 'jour', month: 'mois', year: 'an' }}));
                    } 
                }else if(i18n.resolvedLanguage === "en") {
                    switch (diffDays) {
                        default:
                            setDateSince(timeSince(subDate, {lang: { second: "second", minute: 'minute', hour: 'hour', day: 'day', month: 'month', year: 'year' }}) + " ago");
                    }
                }else {
                    switch (diffDays) {
                        default:
                            setDateSince(timeSinceJP(subDate));
                    }
                }
            })
        }
    }, [billInfo])

    useEffect(() => {
        // Set theme
        if(localStorage.getItem("theme")){
            if(localStorage.getItem("theme") === "light"){
                setTheme('lightTheme');
            }
        }

        // Retreive settings
        retreiveInfos().then(res => {
            setInfos(res);
            Settings.retreiveBills(res.uid).then(sub => setBillInfo(sub))
        })
    }, [localStorage.getItem("theme")])

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://snot.fr/settings/subscription" />
                <meta name="robots" content="noindex,follow"/>
                <meta property="og:title" content={t('title.settings.subscription')}/>
                <meta property="og:url" content={"https://snot.fr/settings/subscription"+window.location.search}/>
                <meta name="twitter:title" content={t('title.settings.subscription')}/>
                <title>{t('title.settings.subscription')}</title>
            </Helmet>
            <Appbar />
            <Navbar />
            <VerticalMenu theme={theme} />
            <main style={{overflow:"auto",height:"100%",position: "absolute",width: "100%"}}>
                <div id="settingsMain">
                    <section className={"topPart_setAccMVB1 "+theme}>
                        <h1 id="title">{t('settings.billing.titleStrong')}</h1>
                    </section>
                    <section className={"settingsPart_setMainMVB1 "+theme}>
                        <div id="AccountSettings" className="privacy accessiblity">
                            {
                                (billInfo !== null && billInfo.length !== 0) ? (
                                <section className="arraySubscription_subSetPcVB1">
                                    <div id="tabs">
                                        <span>{t('settings.subscription.tabs.date')}</span>
                                        <span>{t('settings.subscription.tabs.name')}</span>
                                        <span>{t('settings.subscription.tabs.total')}</span>
                                    </div>
                                    {
                                        billInfo.map((bill, index) => (<>
                                            <div id={"item-"+index} className="item_arrayPcVB1">
                                                <div id="mainInfos" onClick={() => {
                                                    var details = document.getElementById('details');
                                                    var indicator = document.getElementById('upDownIndicator');
                                                    
                                                    if(indicator.classList.contains('fi-rr-angle-down')){
                                                        indicator.classList.remove('fi-rr-angle-down');
                                                        indicator.classList.add('fi-rr-angle-up');
                                                        details.style.display = "block";
                                                        details.classList.remove('hide');
                                                    }else{
                                                        indicator.classList.add('fi-rr-angle-down');
                                                        indicator.classList.remove('fi-rr-angle-up');
                                                        details.style.display = "none";
                                                        details.classList.add('hide');
                                                    }
                                                }}>
                                                    <span>{new Date(bill.date).toLocaleDateString(i18n.resolvedLanguage)}</span>
                                                    <div style={{marginLeft: -10}}>
                                                        {
                                                            bill.name === "SNOTPlus" ? (
                                                                <img src={theme === "darkTheme" ? SNOTPlusDark: SNOTPlusLight} alt="SNOT+" title="SNOT+" />
                                                            ): (
                                                                <img src={theme === "darkTheme" ? SNOTProDark: SNOTProLight} alt="SNOT Pro" title="SNOT Pro" />
                                                            )
                                                        }
                                                        <span style={{marginLeft: bill.name === "SNOTPlus" ? 0: -5}}>{bill.plan} plan</span>
                                                    </div>
                                                    <span>{bill.price} €</span>
                                                    <i className="fi fi-rr-angle-down" id="upDownIndicator"></i>
                                                </div>
                                                <div id="details" className="hide" style={{display: 'none'}}>
                                                    <p style={{margin: '0 0 10px 0'}}>{t('settings.subscription.content.details')}</p>
                                                    {
                                                        (bill.price === 0 && bill.method === "SNOT") ?
                                                        <p style={{margin: '5px 0 10px 0', color: theme === "darkTheme" ? "#9A9A9A": "#707070", fontWeight: 400, fontSize: 14}}>{t('settings.subscription.content.advice')}</p>
                                                        :
                                                        <div id="paymentDetails">
                                                            <div id="logo" style={{height: 40}}>
                                                                {
                                                                    bill.method === "PayPal" && <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="40" height="40" rx="5" fill="#F0F0F0"/>
                                                                        <path d="M26.9746 9.87839C25.8211 8.56366 23.736 8 21.0685 8H13.3268C13.0629 8.00001 12.8076 8.09417 12.6069 8.26554C12.4062 8.43692 12.2732 8.67427 12.2319 8.93493L9.00837 29.3792C8.94429 29.7824 9.25646 30.1474 9.66507 30.1474H14.4445L15.6449 22.5339L15.6076 22.7724C15.6931 22.2342 16.1534 21.8373 16.6984 21.8373H18.9696C23.4314 21.8373 26.925 20.025 27.9455 14.7825C27.9758 14.6275 28.0021 14.4766 28.0248 14.3291C27.8959 14.2609 27.8959 14.2609 28.0248 14.3291C28.3286 12.3915 28.0227 11.0725 26.9746 9.87839Z" fill="#27346A"/>
                                                                        <path d="M17.4737 13.6311C17.6043 13.5689 17.7473 13.5367 17.892 13.5368H23.9613C24.6801 13.5368 25.3504 13.5835 25.963 13.6821C26.1344 13.7095 26.3049 13.7421 26.4743 13.78C26.7144 13.8329 26.9518 13.8978 27.1854 13.9743C27.4866 14.0748 27.7671 14.192 28.0248 14.3291C28.3286 12.3907 28.0227 11.0725 26.9746 9.87839C25.8205 8.56366 23.736 8 21.0685 8H13.3262C12.781 8 12.3173 8.39685 12.2319 8.93493L9.00837 29.3785C8.94429 29.7823 9.25646 30.1469 9.66441 30.1469H14.4445L16.9349 14.3546C16.9593 14.1994 17.0212 14.0525 17.115 13.9264C17.2089 13.8004 17.332 13.6991 17.4737 13.6311Z" fill="#27346A"/>
                                                                        <path d="M27.9453 14.7825C26.9248 20.0242 23.4312 21.8372 18.9694 21.8372H16.6976C16.1525 21.8372 15.6922 22.2342 15.6075 22.7723L14.1142 32.2388C14.0584 32.5916 14.3312 32.9113 14.6882 32.9113H18.7172C18.948 32.9112 19.1712 32.8288 19.3466 32.6789C19.5221 32.529 19.6383 32.3214 19.6744 32.0934L19.7136 31.8881L20.473 27.0755L20.5219 26.8096C20.558 26.5816 20.6742 26.374 20.8496 26.2241C21.0251 26.0742 21.2482 25.9918 21.479 25.9917H22.082C25.9849 25.9917 29.0409 24.4061 29.934 19.8203C30.3067 17.9039 30.1139 16.3039 29.1278 15.1801C28.8287 14.8397 28.4572 14.5586 28.0245 14.3291C28.0011 14.4773 27.9757 14.6274 27.9453 14.7825Z" fill="#2790C3"/>
                                                                        <path d="M26.9566 13.9033C26.7975 13.8569 26.6368 13.8157 26.475 13.78C26.3055 13.7427 26.135 13.7103 25.9636 13.6828C25.3504 13.5835 24.6805 13.5367 23.9611 13.5367H17.8925C17.7477 13.5364 17.6047 13.5689 17.4742 13.6318C17.3324 13.6995 17.2091 13.8008 17.1152 13.9269C17.0213 14.0529 16.9596 14.2 16.9353 14.3553L15.6454 22.534L15.6082 22.7724C15.693 22.2342 16.1533 21.8373 16.6984 21.8373H18.9702C23.432 21.8373 26.9255 20.025 27.9461 14.7825C27.9764 14.6275 28.0019 14.4772 28.0254 14.3292C27.7669 14.1927 27.4872 14.0748 27.186 13.9749C27.11 13.9497 27.0335 13.9259 26.9567 13.9033" fill="#1F264F"/>
                                                                    </svg>
                                                                }
                                                            </div>
                                                            <p style={{margin: '0 0 0 10px'}}>{bill.method} - <span id="date">{dateSince}</span></p>
                                                            {
                                                                bill.method !== "SNOT" && <button className="downloadBtn_detailsSubPcVB1">{t('settings.subscription.content.download')}</button>
                                                            }
                                                        </div>
                                                    }
                                                    <p style={{margin: '20px 0 5px 0'}}>{t('settings.subscription.content.problemTitle')}</p>
                                                    <p id="problemDescription">
                                                        <span>{t('settings.subscription.content.problemSubtitle')}</span>
                                                        <span id="link" data-container="manageSubscription" onClick={openNewMenu}>{t('settings.subscription.content.manageSubscription')}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <aside id="manageSubscription" className="menu" aria-hidden={true} aria-modal={false} style={{display: 'none'}}>
                                                <div className="menu-wrapper js-menu-stop" style={{maxWidth: 'none', height: 'auto', width: 150}}>
                                                    <div className="actionsPart_spostMMVB1">
                                                        <div id="refund" className={bill.price === 0 && 'disabled'}>
                                                            <span>{t('settings.subscription.manage.refund')}</span>
                                                            <i className="fi fi-rr-dollar"></i>
                                                        </div>
                                                        {/*<div id="delete">
                                                            <span>{t('settings.subscription.manage.cancel')}</span>
                                                            <i className="fi fi-rr-cross-circle"></i>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                            </aside>
                                        </>))
                                    }
                                </section>
                                ): (
                                    <section className="arraySubscription_subSetPcVB1">
                                        <div id="tabs">
                                            <span>{t('settings.subscription.tabs.date')}</span>
                                            <span>{t('settings.subscription.tabs.name')}</span>
                                            <span>{t('settings.subscription.tabs.total')}</span>
                                        </div>
                                        <div id="item-no" className="item_arrayPcVB1">
                                            <div id="mainInfos" style={{height: 60}}>
                                                <span>DD/MM/YYYY</span>
                                                <div>
                                                    <span style={{marginLeft: 0}}>{t('settings.billing.error.no-bills')}</span>
                                                </div>
                                                <span>0 €</span>
                                            </div>
                                        </div>
                                    </section>
                                )
                            }
                        </div>
                    </section>
                </div>
            </main>
       </>
    )
}

export default SettingsBilling;